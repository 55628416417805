<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <form method="post" action="javascript:void(0)">
        <b-row>
          <div class="table-responsive">
            <table id="table" class="table table-striped table-bordered" style="width: 100%">
              <thead>
                <tr>
                  <th>SL#</th>
                  <th>Fee Head</th>
                  <th>Month</th>
                  <th>Total</th>
                  <!-- <th>Unpaid</th> -->
                  <th>Due Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(fee, key) in assignedFeeList" :key="key">
                  <td>{{ key + 1 }}</td>
                  <td>{{ fee.fee_head_name }}</td>
                  <td>{{ getMonthName(fee.month_year) }}</td>
                  <td>{{ fee.payable }}</td>
                  <!-- <td>{{ fee.amount }}</td> -->
                  <td>
                    <p :style="
                      fee.payment_last_date >= moment().format('YMD')
                        ? 'color:green'
                        : 'color:red'
                    ">
                      {{ fee.payment_last_date }}
                    </p>
                  </td>
                  <td>
                    <!-- <button @click="completePaymentProcess(fee.payment_details, fee.payment_head_id)" v-if="fee.payable > 0"
                      style="float: right; vertical-align: middle;" class="btn btn-primary">
                      {{ $t("Pay Now") }}
                    </button> -->
                    <!-- <button @click="showPaymentOptions(fee.payment_details, fee.payment_head_id)" v-if="fee.payable > 0"
                      style="float: right; vertical-align: middle;" class="btn btn-primary">
                      {{ $t("Pay Now") }}
                    </button> -->
                    
                    <b-button variant="secondary" @click="viewDetails(fee.payment_details)"
                      style="padding-left:1cm; float: left; vertical-align: middle;" title="View Payable Details">
                      <feather-icon class="text-body " icon="EyeIcon" size="15" />
                    </b-button>

                    <span v-if="fee.payable <= 0" 
                      style="float: right; vertical-align: middle;" class="btn btn-primary">
                      {{ $t("Paid") }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-row>
      </form>
    </validation-observer>

    <b-modal ref="payment-view" hide-footer size="sm" :title="$t('Payment Details')">
      <table class="table table-striped table-bordered" style="border-collapse: collapse; border-spacing: 0 10px; ">
        <thead>
          <tr>
            <th>SL#</th>
            <th>Payment Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(payment_info, i) in payment_detail_array" :key="i">
            <th scope="row">{{ (i + 1)  }}</th>  
            <td>{{ payment_info.fee_type_name }}</td> 
            <td>{{ payment_info.payable }}</td>  
          </tr>
      </tbody>

      </table>
    </b-modal>

    <b-modal ref="payment-modal" hide-footer size="sm" :title="'Select Payment Methods'" >
      <div class="justify-content-center; padding: 15px;">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio style="padding-bottom:1.2rem; vertical-align: middle;" v-model.number="payment_type" :aria-describedby="ariaDescribedby" name="some-radios" value="1"><img  src="../../assets/images/icons/ssl_payment_gateway.png" width="150"  style="border: 1px; vertical-align: top;"/></b-form-radio>
          <b-form-radio style="padding-bottom:1.2rem; vertical-align: middle;" v-model.number="payment_type" :aria-describedby="ariaDescribedby" name="some-radios" value="2"><img src="../../assets/images/icons/tap_payment_gateway.png" width="150"  style="border: 1px;  vertical-align: top;" /></b-form-radio>
          <b-form-radio style="vertical-align: middle;" v-model.number="payment_type" :aria-describedby="ariaDescribedby" name="some-radios" value="3"><img src="../../assets/images/icons/nagad-logo.png" width="100" height="40"  style="border: 1px;  vertical-align: top; padding: 0;" /></b-form-radio>
          <!-- <b-form-radio v-model.number="payment_type" :aria-describedby="ariaDescribedby" name="some-radios" value="4"><img src="../../assets/images/icons/bKash_logo.png" width="100"  style="border: 1px;" /></b-form-radio> -->
        </b-form-group>
        <button type="submit" style="float: left" @click="completePaymentProcess()" class="btn btn-primary">{{ $t('Pay Now') }}</button>
      </div>
    </b-modal>
    <b-modal ref="tap-modal" hide-footer size="md">
      <div v-html="tap_html"></div>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow,BFormRadio, BButton, BFormGroup } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { required } from "@validations";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import apiCall from "@/libs/axios";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "PaymentHistory",
  components: {
    BCardCode,
    BButton,
    BFormRadio,
    BFormGroup,
    BRow,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      total_payable: 0,
      selected_row: {},
      payment_types: [],
      student_id: null,
      academic_year_id: null,
      student: {},
      assignedFeeList: [],
      payment_detail_array: [],
      inputs: [],
      is_loading: false,
      required,
      payment_type:1,
      tap_html:null,
      payment_details: null, 
      payment_head_id: null
    };
  },
  methods: {
    getMonthName(month_id) {
      var month_name = '';
      if (parseInt(month_id) === 1) {
        month_name = 'January';
      }
      else if (parseInt(month_id) === 2) {
        month_name = 'February';
      }
      else if (parseInt(month_id) === 3) {
        month_name = 'March';
      }
      else if (parseInt(month_id) === 4) {
        month_name = 'April';
      }
      else if (parseInt(month_id) === 5) {
        month_name = 'May';
      }
      else if (parseInt(month_id) === 6) {
        month_name = 'June';
      }
      else if (parseInt(month_id) === 7) {
        month_name = 'July';
      }
      else if (parseInt(month_id) === 8) {
        month_name = 'August';
      }
      else if (parseInt(month_id) === 9) {
        month_name = 'September';
      }
      else if (parseInt(month_id) === 10) {
        month_name = 'October';
      }
      else if (parseInt(month_id) === 11) {
        month_name = 'November';
      }
      else if (parseInt(month_id) === 12) {
        month_name = 'December';
      }

      return month_name;
    },
    viewDetails(payment_detail_list) {
      this.payment_detail_array = payment_detail_list;
      this.$refs['payment-view'].show();
    },
    async collectPaymentInformation() {
      await apiCall
        .get("/student/personal/assign/fees")
        .then((response) => {
          this.student = response.data.student;
          this.assignedFeeList = response.data.assignedFeeList;
        })
        .catch(() => {
          this.student = {};
          this.assignedFeeList = [];
        });
    },
    populatePayableAmount(payableItem) {
      this.inputs.push({
        student_id: this.student.id,
        version_id: this.student.version_id,
        classes_id: this.student.classes_id,
        wing_id: this.student.wing_id,
        student_group_id: this.student.student_group_id,
        student_fee_type_id: payableItem.student_fee_type_id,
        total_amount: payableItem.payable,
        fee_amount: (payableItem.payable - payableItem.late_fee_amount > 0) ? (payableItem.payable - payableItem.late_fee_amount) : 0,
        late_fee_amount: (payableItem.late_fee_amount > 0) ? ((payableItem.late_fee_amount - payableItem.payable > 0) ? payableItem.payable : payableItem.late_fee_amount) : 0,
        assign_student_fee_id: payableItem.id,
        canteen_bill_id: payableItem.id,
        academic_year_id: this.student.academic_year_id,
        month_year: payableItem.month_year,
        created_at: new Date().toISOString().slice(0, 10)
      });
    },
    async showPaymentOptions(payment_details, fee_head_id) {
      this.payment_details = payment_details;
      this.payment_head_id = fee_head_id;
     
      this.$refs.simpleRules.validate().then(async success => {
        if (success && !this.is_loading) {
          this.$refs['payment-modal'].show()
        }
      })
    },
    async completePaymentProcess(/*detailPaymentInfoList, payment_head_id*/) {
      let url = ''
      if (this.payment_type === 2){
        url = '/student/tap/payment/initiate' //tap payment url
      }
      else if (this.payment_type === 3){
        url = '/student/nagad/pay' //nagad payment url
      } 
      else if (this.payment_type === 4) {
        url = '/student/bkash/create-payment' //bkash payment url
      }
      else {
        url = '/student/pay-via-ajax'
      }

      //detailPaymentInfoList.forEach(this.populatePayableAmount);
      this.payment_details.forEach(this.populatePayableAmount);
    

      this.$refs.simpleRules.validate().then(async (success) => {
        if (success && !this.is_loading) {
          if (this.inputs.length < 1) return;

          let data = new FormData();
          this.is_loading = true;
          data.append("input", JSON.stringify(this.inputs));
          data.append("payment_head_id", this.payment_head_id);
          data.append("student_id", this.student.id);
         
          await apiCall
            .post(url, data)
            .then((response) => {
              this.is_loading = false;
              if (response.data.status === "success") {
                // Everything is ready to pay and will move the user to ssl payment site by window.location = response.data.data;
                // Not paid yet.
                this.inputs = [];
                this.student = {};
                this.total_payable = 0;
                //window.location = response.data.data;
                if (this.payment_type === 1){
                  window.location = response.data.data;
                }
                else if (this.payment_type === 3){
                  //console.log(response.data);
                  window.location = response.data.url;
                }
                else{
                  window.location = response.data.url;
                } 
              } else {
                this.error = response.data.message;
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                });
              } else this.$toaster.error(error.response.data.message);
              this.is_loading = false;
            });
        }
      });
    },
    removeField(index) {
      //methods for remove filed
      this.inputs.splice(index, 1);
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    ...mapGetters(["student_fee_types", "studentFeeTypeByID"]),
  },
  created() {
    this.collectPaymentInformation();
    if (this.student_fee_types.length < 1)
      this.$store.dispatch("GET_ALL_STUDENT_FEE_TYPE");
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>